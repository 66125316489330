import { roleMap } from '@helpers/SystemUser.helper';
import { AuthRoleEnum } from '@interfaces/Api';

export interface SortConfigProps {
    key: string;
    direction: 'ascending' | 'descending' | '';
}

const handleSortConfigKey = ({ a, b, sortConfig }) => {
    switch (sortConfig?.key) {
        case 'lastLoginDate':
            return {
                itemA: new Date(a?.currentLoginDate).getTime(),
                itemB: new Date(b?.currentLoginDate).getTime(),
            };
        case 'invitationLog': {
            const aLastInvited = new Date(
                a?.invitationLog[a?.invitationLog?.length - 1]?.sentAt
            ).getTime();

            const bLastInvited = new Date(
                b?.invitationLog[b?.invitationLog?.length - 1]?.sentAt
            ).getTime();
            return {
                itemA: aLastInvited,
                itemB: bLastInvited,
            };
        }
        case 'role':
            return {
                itemA: roleMap[a[sortConfig?.key] as AuthRoleEnum],
                itemB: roleMap[b[sortConfig?.key] as AuthRoleEnum],
            };

        default:
            return {
                itemA: a[sortConfig?.key],
                itemB: b[sortConfig?.key],
            };
    }
};

export const handleSorting = (a: any, b: any, sortConfig: SortConfigProps) => {
    const { itemA, itemB } = handleSortConfigKey({ a, b, sortConfig });

    if ((itemA || '') < (itemB || '')) {
        return sortConfig?.direction === 'ascending' ? -1 : 1;
    }
    if ((itemA || '') > (itemB || '')) {
        return sortConfig?.direction === 'descending' ? -1 : 1;
    }
    return 0;
};

export const handleSystemUserFiltering = (val: any, searchTextRaw?: string) => {
    const normalizeText = (text: string) => 
        text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();
    
    const eachWordFilter = searchTextRaw!
        .toLocaleLowerCase()
        .split(' ')
        .map((searchText) => {
            const normalizedSearchText = normalizeText(searchText);

            const findInSlug = normalizeText(val?.slug ?? '')
                .includes(normalizedSearchText);
            const findInRole = normalizeText(roleMap[val.role as AuthRoleEnum] ?? '')
                .includes(normalizedSearchText);
            const findInEmail = normalizeText(val?.email ?? '')
                .includes(normalizedSearchText);
            const findInOwner = normalizeText(val?.owner ?? '')
                .includes(normalizedSearchText);

            return findInSlug || findInRole || findInOwner || findInEmail;
        });

    return eachWordFilter.every((a) => a === true);
};

