import { generateSpecFiles } from '@api/Files';
import { Badge, BadgeType, Button, Icons, Loading } from '@components/Atoms';
import Grid from '@components/Atoms/Grid/Grid';
import { SubscriptionTimeline } from '@components/Molecules';
import { ModalWrapper } from '@components/Organisms';
import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { formatDate } from '@helpers/Date.helper';
import { downloadFileOnTheClient } from '@helpers/File.helper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import {
    ProductResponse,
    ShareClass,
    SigningDocument,
    SigningUserStatusEnum,
    SubscriptionStatusEnum,
    SubscriptionStatusLog,
} from '@interfaces/Api';
import { SigningUser } from '@interfaces/Api/SigningDocument';
import { SubscriptionSigningDocumentTypeEnum } from '@interfaces/Api/SigningDocumentTypeEnum';
import { SubscriptionStatusTimelineActivity } from '@interfaces/Api/SubscriptionTimeline';

import Drawer from '@mui/material/Drawer';
import {
    GridActionsCellItem,
    GridColDef,
    GridInitialState,
    GridRowsProp,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    gridFilteredSortedRowIdsSelector,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ModalService } from '@services/ModalService';
import { useSubscriptionsActions } from '@stores/Subscriptions';
import { handleGetSubscriptionStatusTimeline } from '@stores/Subscriptions/Subscriptions.services';
import { AuthPermissionEnum, ClientEnum } from 'common';
import { startCase } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface SubscriptionGridItemVersion1 {
    subscriptionId: string;
    subscriptionUrl: string;
    status: SubscriptionStatusEnum;
    endInvestorName?: string;
    product: ProductResponse;
    shareClass?: ShareClass;
    subscriptionAmount?: number;
    subscriptionCurrencyCode?: string;
    signingDocuments?: SigningDocument[];
    statusLogs?: SubscriptionStatusLog[];
    firstName?: string;
    lastName?: string;
    companyName?: string;
    submissionDate?: Date;
    closingDate?: Date;
    workflowStatus?: string;
    distributor?: string;
    relationshipManager?: string;
    creationDate?: Date;
    latestTimeActivity?: Date;
    riskLevel?: string;
    assignedTo?: string;
}
interface Props {
    gridName: string;
    endInvestorProfileId?: string;
    subscriptions: SubscriptionGridItemVersion1[];
    onDeleteSubscription?: (subscriptionId: string) => {};
    totalRows?: number;
    serverSide?: boolean;
    onLoadData?: (queryString: string) => void;
    initialState?: GridInitialState;
}

export const SubscriptionsGridVersion1 = ({
    gridName,
    endInvestorProfileId,
    subscriptions,
    totalRows,
    serverSide,
    onLoadData,
    onDeleteSubscription,
    initialState,
}: Props) => {
    const { t } = useTranslation();
    const apiRef = useGridApiRef();
    const { hasPermissions } = useAuthState();
    const { isClient } = useConfigurationState();
    const [gridRows, setGridRows] = useState<GridRowsProp>();

    const [specFilesGenerating, setSpecFilesGenerating] =
        useState<boolean>(false);
    const { complianceRejection, fetchSubscriptions } =
        useSubscriptionsActions();

    const [statusTimeline, setStatusTimeline] =
        useState<SubscriptionStatusTimelineActivity[]>();

    const [statusTimelineSubscriptionId, setStatusTimelineSubscriptionId] =
        useState<string>();

    const [isRejectingSubscription, setIsRejectingSubscription] =
        useState<boolean>(false);

    useEffect(() => {
        if (!subscriptions?.length) return;
        setGridRows(subscriptions);
    }, [subscriptions]);

    const deleteSubscription = useCallback(
        (id) => () => {
            onDeleteSubscription(id);
        },
        [onDeleteSubscription]
    );

    const showStatusLogs = async (subscriptionId) => {
        const timeline = await handleGetSubscriptionStatusTimeline(
            subscriptionId
        );

        setStatusTimeline(timeline);
        setStatusTimelineSubscriptionId(subscriptionId);
    };

    const rejectSubscription = async (subscriptionId) => {
        try {
            setIsRejectingSubscription(true);
            await complianceRejection({
                body: {
                    statusComment: 'Rejected by Compliance',
                },
                subscriptionId,
            });

            notifySuccess('Subscription application has been rejected');
        } catch (err) {
            notifyError(
                'A problem has occurred when rejecting this application'
            );
        } finally {
            fetchSubscriptions(endInvestorProfileId);
            setIsRejectingSubscription(false);
        }
    };

    const hasUserSigned = (signingUser: SigningUser) =>
        signingUser && signingUser.status === SigningUserStatusEnum.signed;

    const getDocumentStatus = (
        signingDocuments: SigningDocument[]
    ):
        | {
              badge: BadgeType;
              label: string;
          }
        | undefined => {
        if (!signingDocuments || signingDocuments.length === 0) return;

        const isSignedByInvestor = signingDocuments.some((doc) =>
            hasUserSigned(doc.signingUsers[0])
        );
        const isCounterSigned = signingDocuments.some((doc) =>
            hasUserSigned(doc.signingUsers[1])
        );

        if (isSignedByInvestor && isCounterSigned) {
            return {
                badge: 'success',
                label: t('subscriptions.grid.document_status_counter_signed'),
            };
        } else if (isSignedByInvestor) {
            return {
                badge: 'secondary',
                label: t(
                    'subscriptions.grid.document_status_signed_by_investor'
                ),
            };
        } else {
            return {
                badge: 'supplementary',
                label: t('subscriptions.grid.document_status_not_signed'),
            };
        }
    };

    const renderDocumentStatus = (badgeType: BadgeType, label: string) => {
        return <Badge badgeType={badgeType} sizeType="medium" label={label} />;
    };

    const renderSigningDocumentStatus = (
        signingDocuments: SigningDocument[]
    ) => {
        const status = getDocumentStatus(signingDocuments);
        if (!status) return;

        return renderDocumentStatus(status.badge, status.label);
    };

    const handleGenerateSpecFiles = async () => {
        setSpecFilesGenerating(true);

        const filteredSubscriptionIds = gridFilteredSortedRowIdsSelector(
            apiRef.current.state,
            apiRef.current.instanceId
        ).map((id) => id.toString());

        if (filteredSubscriptionIds.length) {
            const result = await generateSpecFiles(filteredSubscriptionIds);

            if (result) {
                notifySuccess('SUCCESS');
            } else {
                notifyError('FAILED');
            }

            downloadFileOnTheClient(result);
        }
        setSpecFilesGenerating(false);
    };

    const canGenerateSpecFiles =
        isClient(ClientEnum.ODDO) &&
        hasPermissions([
            AuthPermissionEnum.generateSpecFile,
            AuthPermissionEnum.generatePecFile,
        ]);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                />

                {canGenerateSpecFiles && (
                    <div className="flex justify-end">
                        <Button
                            id="generate-spec-files"
                            label={`Generate SPEC files`}
                            type="button"
                            buttonType="preview"
                            size="small"
                            onClick={handleGenerateSpecFiles}
                            isLoading={specFilesGenerating}
                        />
                    </div>
                )}
            </GridToolbarContainer>
        );
    };

    const handleComplianceRejection = (subscriptionId) =>
        ModalService.getInstance('compliance-rejection')
            .setTitle(t('client.compliance_rejection.warning.title'))
            .setComponent(
                <div>
                    <div className="mt-4">
                        <strong>
                            {t('client.compliance_rejection.warning.text1')}
                        </strong>
                    </div>

                    <div
                        className="relative px-2 py-3 mt-4 text-red-700 bg-red-100 rounded"
                        role="alert"
                    >
                        <div className="font-bold">
                            <span className="block sm:inline">
                                {t('client.compliance_rejection.warning.text2')}
                            </span>
                        </div>
                    </div>

                    <div className="flex justify-between mt-8">
                        <Button
                            buttonType="secondary"
                            onClick={() =>
                                ModalService.getInstance(
                                    'compliance-rejection'
                                ).hideModal()
                            }
                            label={t(
                                'subscriptions.grid.compliance_rejection.abort'
                            )}
                        />

                        <Button
                            label={t(
                                'subscriptions.grid.compliance_rejection.execute'
                            )}
                            onClick={async () => {
                                await rejectSubscription(subscriptionId);
                                ModalService.getInstance(
                                    'compliance-rejection'
                                ).hideModal();
                            }}
                        />
                    </div>
                </div>
            )
            .showModal();

    const gridColumns: GridColDef[] = [
        {
            field: 'endInvestorName',
            headerName: `${t('subscriptions.grid.column.end_investor_name')}`,
        },
        {
            field: 'fundName',
            headerName: `${t('subscriptions.grid.column.fund_name')}`,
            hideable: false,
            minWidth: 450,
            renderCell: (params) => {
                return (
                    <Link
                        to={params.row.subscriptionUrl}
                        className="text-blue-800 underline hover:text-blue-800 "
                    >
                        {params.value}
                    </Link>
                );
            },
        },
        {
            field: 'subscriptionCurrencyCode',
            headerName: `${t('subscriptions.grid.column.investment_currency')}`,
        },
        {
            field: 'subscriptionAmount',
            headerName: `${t('subscriptions.grid.column.investment_amount')}`,
            renderCell: (params) => (
                <span>
                    {getFormattedCurrency(
                        params.row.subscriptionAmount,
                        params.row.subscriptionCurrencyCode
                    )}
                </span>
            ),
        },
        {
            field: 'status',
            headerName: `${t('subscriptions.grid.column.subscription_status')}`,
            renderCell: (params) => <span>{startCase(params.row.status)}</span>,
        },
        {
            field: 'workflowStatus',
            headerName: `${t('subscriptions.grid.column.workflow_status')}`,
            renderCell: (params) => (
                <span>{startCase(params.row.workflowStatus)}</span>
            ),
        },
        {
            field: 'assignedTo',
            headerName: `${t('subscriptions.grid.column.assigned_to')}`,
            align: 'center',
            renderCell: (params) => {
                return <span>{params.row.assignedTo || '-'}</span>;
            },
        },
        {
            field: 'riskLevel',
            headerName: t('subscriptions.grid.column.risk_level'),
            align: 'center',
            renderCell: (params) => {
                return <span>{params.row.riskLevel || '-'}</span>;
            },
        },
        {
            field: 'SigningDocuments',
            valueGetter: (params) =>
                getDocumentStatus(params.row.signingDocuments)?.label,
            headerName: 'Document Status',
            renderCell: (params) => {
                return renderSigningDocumentStatus(params.row.signingDocuments);
            },
        },
        {
            field: 'closingDate',
            headerName: `${t('subscriptions.grid.column.closing_date')}`,
            type: 'date',
            valueGetter: (params) =>
                params.row.closingDate && new Date(params.row.closingDate),
            valueFormatter: ({ value }) => formatDate(value),
        },
        {
            field: 'latestTimeActivity',
            headerName: `${t('subscriptions.grid.column.latest_activity')}`,
            type: 'date',
            valueGetter: (params) =>
                params.row.latestTimeActivity &&
                new Date(params.row.latestTimeActivity),
            valueFormatter: ({ value }) => formatDate(value),
        },
        {
            field: 'distributor',
            headerName: `${t('subscriptions.grid.column.distributor')}`,
        },
        {
            field: 'relationshipManager',
            headerName: `${t(
                'subscriptions.grid.column.relationship_manager'
            )}`,
        },
        {
            field: 'creationDate',
            headerName: `${t('subscriptions.grid.column.creation_date')}`,
            type: 'date',
            valueGetter: (params) =>
                params.row.creationDate && new Date(params.row.creationDate),
            valueFormatter: ({ value }) => formatDate(value),
        },
        {
            field: 'Actions',
            type: 'actions',
            align: 'right',
            getActions: (params) => {
                const allowedActions = [];
                const canRejectSubscription =
                    hasPermissions([AuthPermissionEnum.complianceRejection]) &&
                    (params.row.status === SubscriptionStatusEnum.approved ||
                        params.row.status ===
                            SubscriptionStatusEnum.readyForReview);

                const canDeleteSubscription =
                    onDeleteSubscription &&
                    params.row.status === SubscriptionStatusEnum.inProgress;

                if (canRejectSubscription) {
                    allowedActions.push(
                        <GridActionsCellItem
                            key={params.id}
                            icon={
                                <Icons name="XMarkIconSolid" stroke="#991b1b" />
                            }
                            label={`${t(
                                'subscriptions.grid.subscription_reject'
                            )}`}
                            onClick={() => handleComplianceRejection(params.id)}
                        />
                    );
                }

                if (canDeleteSubscription) {
                    allowedActions.push(
                        <GridActionsCellItem
                            key={params.id}
                            icon={<Icons name="TrashIcon" />}
                            label={`${t(
                                'subscriptions.grid.subscription_delete'
                            )}`}
                            onClick={deleteSubscription(params.id)}
                        />
                    );
                }

                allowedActions.push(
                    <GridActionsCellItem
                        key={params.id}
                        icon={<Icons name="ListBulletIcon" />}
                        label={`${t(
                            'subscriptions.grid.subscription_status_logs'
                        )}`}
                        showInMenu
                        onClick={() => showStatusLogs(params.id)}
                    />
                );

                return allowedActions;
            },
        },
    ];

    return (
        <div className="pb-16">
            <h1 className="text-3xl text-logo-blue">
                {t('navbar.subscriptions')}
            </h1>

            <Drawer
                anchor="right"
                open={!!statusTimeline}
                onClose={() => setStatusTimeline(undefined)}
            >
                {statusTimeline && (
                    <SubscriptionTimeline
                        subscriptionId={statusTimelineSubscriptionId}
                        timeline={statusTimeline}
                        handleClose={() => setStatusTimeline(undefined)}
                    />
                )}
            </Drawer>

            <div className="mt-6">
                {(gridRows && (
                    <Grid
                        name={gridName}
                        apiRef={apiRef}
                        rows={gridRows}
                        serverSide={serverSide}
                        totalRows={totalRows}
                        onLoadData={onLoadData}
                        columns={gridColumns}
                        slots={{ toolbar: customToolbar }}
                        slotProps={{
                            columnsPanel: {
                                disableHideAllButton: true,
                                disableShowAllButton: true,
                            },
                            toolbar: {
                                csvOptions: {
                                    getRowsToExport:
                                        gridFilteredSortedRowIdsSelector,
                                },
                            },
                        }}
                        initialState={{
                            ...initialState,
                            columns: {
                                columnVisibilityModel: {
                                    ...initialState?.columns
                                        ?.columnVisibilityModel,
                                    distributor: false,
                                    relationshipManager: false,
                                    creationDate: false,
                                },
                            },
                        }}
                    />
                )) || <p>{t('client.subscriptions.no_subscriptions')}</p>}
            </div>
            <ModalWrapper msgPrefix="compliance-rejection" />

            {isRejectingSubscription && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <Loading size="large" />
                </div>
            )}
        </div>
    );
};
