import { SubscriptionResponse } from '@interfaces/Api';
import { CompletenessCheckType } from 'common';
import { toCapitalizedWords } from './ToCapitalizedWords';

export const getFullName = (firstName: string, surname: string) =>
    [firstName, surname].filter((a) => a).join(' ');

/**
 * Trims whitespace from all string values in the given object recursively.
 * @param obj - The object to trim.
 */
export const trimData = (obj: any) => {
    if (typeof obj === 'object') {
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                obj[key] = obj[key].trim();
            } else if (typeof obj[key] === 'object') {
                trimData(obj[key]);
            }
        }
    }
};

/**
 * Returns the risk level of the given subscription.
 * @param subscription - The subscription to get the risk level from.
 */
export const getRiskLevelFromSubscription = (
    subscription: SubscriptionResponse
) => {
    const currentRiskDecision = subscription?.completenessChecks?.statusLogs
        ?.filter((c) => c.type === CompletenessCheckType.riskIndication)
        ?.sort((a, b) => {
            return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );
        })?.[0]?.decision;
return currentRiskDecision;
};

