import { useEffect, useState } from 'react';
import { Loading } from '@components/Atoms';
import { SubscriptionGridItem } from '@pages/Subscriptions/SubscriptionsGrid';
import { SubscriptionsGridGetAll } from '@stores/Subscriptions/Subscriptions.services';
import { SubscriptionsGridVersion1 } from './SubscriptionsGridVersion1';
const serverSide = false;
const SubscriptionsToolsViewVersion1 = () => {
    const [subscriptions, setSubscriptions] =
        useState<SubscriptionGridItem[]>(undefined);
    const [totalRows, setTotalRows] = useState(0);
    const [queryString, setQueryString] = useState('');
    useEffect(() => {
        const query = queryString ? `${queryString}&serverSide=${serverSide}` : `serverSide=${serverSide}&pageSize=11`;
        SubscriptionsGridGetAll(undefined, query).then((response) => {
            setSubscriptions(response.data);
            setTotalRows(response.totalRows);
        });
    }, [queryString]);

    const onLoadData = (queryString: string) => {
        setQueryString(queryString);
    };

    return (
        <div className="mb-6">
            <div>
                {!subscriptions ? (
                    <Loading size="large" />
                ) : (
                    <div>
                        <SubscriptionsGridVersion1
                            gridName="SubscriptionsToolsView"
                            serverSide={serverSide}
                            onLoadData={onLoadData}
                            totalRows={totalRows}
                            subscriptions={subscriptions}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionsToolsViewVersion1;
